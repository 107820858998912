import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import HiresPhoto from "./HiresPhoto"
const HiresPhotosList = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiHiresPhotos {
        edges {
          node {
            bluespages_link
            thumbnail {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FIXED
                    width: 200
                    quality: 100
                    aspectRatio: 1.7778
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const imageCard = data.allStrapiHiresPhotos.edges.map(({ node }, index) => {
    return (
      <HiresPhoto
        thumbnail={node.thumbnail.localFile}
        link={node.bluespages_link}
        key={index}
      />
    )
  })

  return <ul className="press-image-downloads-images">{imageCard}</ul>
}

export default HiresPhotosList
