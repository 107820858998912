import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import Icon from "@mdi/react"
import {
  mdiFacebook,
  mdiInstagram,
  mdiTwitter,
  mdiReddit,
  mdiTwitch,
  mdiYoutube,
  mdiVimeo,
  mdiPhone,
  mdiEmail,
  mdiOpenInNew,
  mdiLinkVariant,
  mdiDownloadBox,
  mdiClose,
} from "@mdi/js"

import Layout from "../components/layout"
import HiresPhotosList from "../components/Press/HiresPhotosList"
import SEO from "../components/seo"

import "../styles/press.css"
import "../styles/image-modal.css"

const PressPage = ({ data }) => {
  const nonNullField = field =>
    field !== null && field !== "" && field !== undefined

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalImage, setModalImage] = useState(null)

  // const modalClick = target => {
  //   setModalImage(target)
  //   if (modalIsOpen === false) {
  //     setModalIsOpen(true)
  //     document.getElementById("image-modal").showModal()
  //   } else {
  //     document.getElementById("image-modal").close()
  //     setModalIsOpen(false)
  //   }
  // }

  return (
    <Layout>
      <SEO title="Press Kit" />
      <div className="press">
        <div className="press-hero">
          <GatsbyImage
            className="press-hero-pic"
            image={getImage(data.strapiPressKit.press_kit_hero.localFile)}
            loading="eager"
            placeholder="dominantColor"
            alt=""
          />
          <h1 className="press-title-main">{data.strapiPressKit.title}</h1>
        </div>
        <div className="press-main">
          <div className="press-contact press-section">
            <h2 className="press-title-sub">Contact Info</h2>
            <ul className="press-contact-list">
              <li className="press-contact-list-item">
                <a href={`tel: ${data.strapiFooter.contact_links.call_SMS}`}>
                  <Icon
                    className="press-contact-icon"
                    path={mdiPhone}
                    size={3}
                  />
                  <div className="press-contact-list-info">
                    <p>Call or SMS</p>
                  </div>
                </a>
              </li>
              <li className="press-contact-list-item">
                <a
                  href={`mailto: ${data.strapiFooter.contact_links.email_address}`}
                >
                  <Icon
                    className="press-contact-icon"
                    path={mdiEmail}
                    size={3}
                  />
                  <div className="press-contact-list-info">
                    <p>Email</p>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div className="press-bio press-section">
            <h2 className="press-title-sub">Bio</h2>
            <div className="press-bio-image-container">
              <GatsbyImage
                imgClass="press-bio-image"
                image={getImage(data.strapiCmbBio.bio_image.localFile)}
                alt=""
              />
            </div>
            <p className="press-text">{data.strapiCmbBio.bio_blurb}</p>
            <Link to="/bio/">
              <button className="button-style" type="button">
                Read More...
              </button>
            </Link>
          </div>
          <div className="press-reviews press-section">
            <h2 className="press-title-sub">Reviews and Press</h2>
            <ul className="press-reviews-list press-list">
              {data.allStrapiReviews.edges.map(({ node }, index) => {
                if (nonNullField(node.web_link)) {
                  return (
                    <li
                      className="press-reviews-list-item press-card"
                      key={index}
                    >
                      <a
                        href={node.web_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="press-reviews-header">
                          <Icon
                            className="press-reviews-icon"
                            path={mdiOpenInNew}
                            size={1.25}
                          />
                          <h3 className="press-reviews-list-title">
                            {node.title}
                          </h3>
                        </div>
                        {nonNullField(node.publication) && (
                          <h4 className="press-reviews-publication">
                            {node.publication}
                          </h4>
                        )}
                        {nonNullField(node.date_of_review) && (
                          <p className="press-review-date">
                            {node.date_of_review}
                          </p>
                        )}
                      </a>
                      {nonNullField(node.direct_quote) && (
                        <div className="press-text">
                          <ReactMarkdown children={node.direct_quote} />
                        </div>
                      )}
                    </li>
                  )
                } else {
                  return (
                    <li
                      className="press-reviews-list-item press-card"
                      key={index}
                    >
                      <h3 className="press-reviews-list-title">{node.title}</h3>
                      {nonNullField(node.publication) && (
                        <h4 className="press-reviews-publication">
                          {node.publication}
                        </h4>
                      )}
                      {nonNullField(node.date_of_review) && (
                        <p className="press-review-date">
                          {node.date_of_review}
                        </p>
                      )}
                      {nonNullField(node.direct_quote) && (
                        <div className="press-text">
                          <ReactMarkdown children={node.direct_quote} />
                        </div>
                      )}
                    </li>
                  )
                }
              })}
            </ul>
          </div>
          <div className="press-image-downloads press-section">
            <h2 className="press-title-sub">High-resolution Photo Links</h2>
            <HiresPhotosList />
          </div>
          <div className="press-stage-plot press-section">
            <h2 className="press-title-sub">Stage Plot</h2>
            <GatsbyImage
              className="press-stage-plot-image"
              image={getImage(data.strapiPressKit.stage_plot.localFile)}
              alt=""
              loading="lazy"
              objectFit="contain"
              onClick={() =>
                modalClick(data.strapiPressKit.stage_plot.localFile)
              }
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allStrapiReviews(sort: { fields: date_of_review, order: DESC }) {
      edges {
        node {
          date_of_review(formatString: "MM-DD-YYYY")
          direct_quote
          publication
          title
        }
      }
    }
    strapiPressKit {
      title
      press_kit_hero {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, quality: 100)
          }
        }
      }
      reviews {
        direct_quote
        web_link
        publication
        title
        date_of_review
      }
      stage_plot {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, quality: 100)
          }
        }
      }
    }
    strapiFooter {
      social_media_links {
        facebook_link
      }
      contact_links {
        call_SMS
        email_address
      }
    }
    strapiCmbBio {
      bio_image {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, quality: 100)
          }
        }
      }
      bio
      bio_blurb
    }
  }
`

export default PressPage
