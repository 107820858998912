import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Icon } from "@mdi/react"
import { mdiOpenInNew } from "@mdi/js"

const HiresPhoto = props => {
  const { thumbnail, link } = props
  console.log(link)
  return (
    <li className="press-image-downloads-images-item">
      <GatsbyImage
        className="press-image-downloads-image"
        image={getImage(thumbnail)}
        alt=""
        loading="lazy"
        placeholder="dominantColor"
      />
      <a
        className="press-image-downloads-download"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon className="download-icon" path={mdiOpenInNew} size={1} />
        <div className="press-image-downloads-download-text">Hi-res Photo</div>
      </a>
    </li>
  )
}

export default HiresPhoto
